import { Person } from './models/person';
import { Address } from './models/address';
import { Hymn } from './models/hymn';
import { Announcement } from './models/announcement';
import { Missionary } from './models/missionary';
import { CheatSheet } from './models/cheat-sheet';
import { Broadcast } from './models/broadcast';
import { SACRAMENT_PROGRAM_DATA } from './data/sacrament-program.data';

interface PeopleObject {
  [key: string]: Person;
}

interface AddressObject {
  [key: string]: Address;
}

interface HymnsObject {
  [key: string]: Hymn;
}

export const DATA: {
  people?: PeopleObject;
  addresses?: AddressObject;
  hymns?: HymnsObject;
  announcements?: Announcement[];
  releases?: Person[];
  sustaining?: Person[];
  aaronicPriesthoodAdvancements?: Person[];
  // aaronicPriesthoodAdvancementsTeacher?: Person[];
  // aaronicPriesthoodAdvancementsPriest?: Person[];
  missionaries?: Missionary[];
  newMembers?: Person[];
  baptisms?: Person[];
  babyBlessing?: Person[];
  cheatSheet?: CheatSheet;
  broadcastLinks?: Broadcast[];
} = {};

//This is for the announcement section
DATA.announcements = [
  {
    title: 'Protecting Children and Youth Training',
    //date: new Date(2021, 1, 4).toUTCString(),
    description:
      'All leaders and teachers of children or youth ' +
      'are to complete this training within one month ' +
      'of being called. We also invite the parents to ' +
      'do the same. This Training is good for 3 years ' +
      'To take the training, <a class="btn btn-sm btn-info font-size-14 text-capitalize" ' +
      'href="https://www.churchofjesuschrist.org/callings/church-safety-and-health/protecting-children-and-youth?lang=eng&cid=rdb_v_protectingchildren" ' +
      'target="_blank">Click Here!</a> ',
  },
  {
    title: '7:00pm - YSA Institute',
    date: new Date(2023, 6, 6).toUTCString(),
    description:
      'Stake Center, ' +
      '<a href="https://goo.gl/maps/RvUDX51bqqeHVh8r7" target="_blank">680 North 350 West, American Fork</a>,',
  },
  {
    title: '8:00am - Stake Baptism at Ward Level',
    date: new Date(2023, 6, 1).toUTCString(),
    description:
      'Lehi YSA Stake Building, ' +
      '<a href="https://goo.gl/maps/42tAnxMzUrME5yeY9" target="_blank">1550 South  1100 West, Lehi</a>, ',
  },
  {
    title: '8:00am - Utah Food Bank Delivery',
    date: new Date(2023, 6, 6).toUTCString(),
    description:
      'Saratoga Spring 12 Branch Parking Lot, ' +
      '<a href="https://goo.gl/maps/GCA2rMwdfKwQbxZu5" target="_blank">1149 North 300 West Lehi UT 84043</a>, ' +
      '8:00am. If you like to volunteer, be there at 6:30am.',
  },
  {
    title: 'Stake Pickleball Tournament - 6am',
    date: new Date(2023, 6, 4).toUTCString(),
    description:
      'Patriot Park, ' +
      '<a href="https://goo.gl/maps/J6H1DnaRDvy3EAue9" target="_blank">361 South Saratoga Road, Saratoga Springs </a>,',
  },
  {
    title: 'Stake Primary Training - Fireside: 6:00am',
    date: new Date(2023, 6, 16).toUTCString(),
    description:
      'Stake Center, ' +
      '<a href="https://goo.gl/maps/RvUDX51bqqeHVh8r7" target="_blank">680 North 350 West, American Fork</a>,',
  },

  {
    title:
      'Stake Temple Night every Tuesday at 8:30pm: ' +
      '1st and 2nd Tuesday of the Month will be in Tongan ' +
      'and Samoan: Schedule appointments online walk-in also ' +
      'available',
    date: new Date(2023, 6, 4).toUTCString(),
    description:
      'Click Here for Direction, ' +
      '<a href="https://goo.gl/maps/nM3xqwEthviETCE8A" target="_blank">Mount Timpanogos Temple</a>,',
  },
];

DATA.broadcastLinks = [
  // ---------------------------------------------------------------------
  // Sacrament
  // ---------------------------------------------------------------------

  //NOTE: if active=true, it means the info will show on site

  //1st & 3rd Sundays
  {
    active: false,
    label: 'Sunday 7:00am - Ward Council Meeting',
    link: 'https://zoom.us/j/93117919917?pwd=OTNWLy9qMzA0SHZpQ3VYNzlwQ3prQT09',
    passcode: '103839',
    meetingId: '931 1791 9917',
  },

  //4th Sundays
  // {
  //   active: true,
  //   label: 'Sunday 7:30am - Youth Council Meeting',
  //   link: 'https://zoom.us/j/94703693144?pwd=ZHdLZkQ4WHBsRXd4TUFiazJUSFByUT09',
  //   passcode: '821617',
  //   meetingId: '947 0369 3144',
  // },
  {
    active: false,
    label: 'Sunday 10:00am - 2nd/4th Sunday Primary Singing Time',
    link: '',
    passcode: '',
    meetingId: '',
  },
  {
    active: false,
    label: 'Sunday 09:00am - Sacrament Meeting',
    link: 'https://zoom.us/j/98949390101?pwd=TkJUT1FGWng5M2tka2daQTV6N2RCQT09',
    passcode: '914355',
    meetingId: '989 4939 0101',
  },

  // ---------------------------------------------------------------------
  // Sunday School
  // ---------------------------------------------------------------------

  {
    active: false,
    label: 'Sunday 10:00am - Sunday School Gospel Doctrine (English)',
    link: 'https://zoom.us/j/99662113868?pwd=dU1nY1F0MUxHYWVoVHB0N2NocjhoQT09',
    passcode: '228818',
    meetingId: '996 6211 3868',
  },
  {
    active: false,
    label: 'Sunday 10:00am - Sunday School Gospel Doctrine (Tongan)',
    link: 'https://zoom.us/j/99662113868?pwd=dU1nY1F0MUxHYWVoVHB0N2NocjhoQT09',
    passcode: '228818',
    meetingId: '996 6211 3868',
  },
  {
    active: false,
    label: 'Sunday 10:00am - Sunday School - Youth Class (12-14)',
    link: 'https://zoom.us/j/99662113868?pwd=dU1nY1F0MUxHYWVoVHB0N2NocjhoQT09',
    passcode: '228818',
    meetingId: '996 6211 3868',
  },
  {
    active: false,
    label: 'Sunday 10:00am - Sunday School - Youth Class (15-18)',
    link: 'https://zoom.us/j/99662113868?pwd=dU1nY1F0MUxHYWVoVHB0N2NocjhoQT09',
    passcode: '228818',
    meetingId: '996 6211 3868',
  },
  {
    active: false,
    label:
      'Sunday 2:30pm - Sunday School - Teacher Council Meeting (Primary/EQ/RS/YW teachers)',
    link: '',
    passcode: '',
    meetingId: '',
  },

  // ---------------------------------------------------------------------
  // 2nd Hour Org Class
  // ---------------------------------------------------------------------

  // {
  // active: false,
  // label: 'Sunday 2:00pm - 5th Sunday Combined Class',
  // link:
  //   'https://us02web.zoom.us/j/81019714798',
  //passcode: '',
  //meetingId: '839 0304 6539',
  //},
  {
    active: false,
    label: 'Sunday 10:00am - Elders Quorum',
    link: 'https://zoom.us/j/99288707070?pwd=d24wdmtiSGtYOEVVNEM1bmRPTlhXUT09',
    passcode: '523025',
    meetingId: '992 8870 7070',
  },
  {
    active: false,
    label: 'Sunday 10:00am - Elders Quorum (Tongan)',
    link: 'https://zoom.us/j/99288707070?pwd=d24wdmtiSGtYOEVVNEM1bmRPTlhXUT09',
    passcode: '523025',
    meetingId: '992 8870 7070',
  },
  {
    active: false,
    label: 'Sunday 10:00am - Relief Society',
    link: 'https://zoom.us/j/99288707070?pwd=d24wdmtiSGtYOEVVNEM1bmRPTlhXUT09',
    passcode: '523025',
    meetingId: '992 8870 7070',
  },
  {
    active: false,
    label: 'Sunday 10:00am - Relief Society (Tongan)',
    link: 'https://zoom.us/j/99288707070?pwd=d24wdmtiSGtYOEVVNEM1bmRPTlhXUT09',
    passcode: '523025',
    meetingId: '992 8870 7070',
  },
  {
    active: false,
    label: 'Sunday 10:00am - Young Women Class',
    link: 'https://zoom.us/j/99288707070?pwd=d24wdmtiSGtYOEVVNEM1bmRPTlhXUT09',
    passcode: '523025',
    meetingId: '992 8870 7070',
  },
  {
    active: false,
    label: 'Sunday 10:00am - Young Men Class',
    link: 'https://zoom.us/j/99288707070?pwd=d24wdmtiSGtYOEVVNEM1bmRPTlhXUT09',
    passcode: '523025',
    meetingId: '992 8870 7070',
  },
  {
    active: false,
    label: 'Sunday 6:30pm - Great to be 8 | Primary Baptism Preview',
    link: '',
    passcode: '',
    meetingId: '',
  },
  {
    active: false,
    label: 'Saturday 4:00pm - Leadership Training Meeting',
    link: '',
    passcode: '',
    meetingId: '',
  },
  {
    active: false,
    label: 'Sunday 5:00pm - Self-Reliance Class',
    link: 'https://zoom.us/j/97387256111?pwd=SVhOdmVYNjF4RVl4SUI2cGFXMkxhUT09',
    passcode: '171391',
    meetingId: '973 8725 6111',
  },
  //------------------------------------------------------------
  //Ward Conference
  //------------------------------------------------------------
  //{
  //active: false,
  //label: 'Mar. 9 - Tuesday 6:30pm - Stake Young Women Leadership Training',
  //link:
  //  'https://zoom.us/j/92092221065?pwd=cGhscTd1YnZHOGVnY0lFN3k0OWJYQT09',
  //passcode: '807072',
  //meetingId: '920 9222 1065',
  //},
  //{
  //active: false,
  //label: 'Mar. 11 - Thursday 6:30pm - Stake Relief Society Leadership Training',
  //link:
  //'https://us02web.zoom.us/j/89207973068?pwd=Rmc3dlZWNVJCVEx4dVdJdzgyakZhdz09',
  //passcode: '648680',
  //meetingId: '892 0797 3068',
  //},
  //{
  //active: false,
  //label: 'Mar. 13 - Saturday 9:00am - Stake Young Men Leadership Training (Bishopric & Advisors)',
  //link:
  //'https://us02web.zoom.us/j/87340936691?pwd=UGFKcm9EbEwxaGJVL0FmSWlqU3Vvdz09',
  //passcode: '091241',
  //meetingId: '873 4093 6691',
  //},
  //{
  //active: false,
  //label: 'Mar. 14 - Sunday 10:00am - Ward Council Leadership Training',
  //link: 'https://us02web.zoom.us/j/86349237049?pwd=NmozVjhNSjkrZmZGcFQzMzVTYTNIdz09',
  //passcode: 'WRDCOUNCIL',
  //meetingId: '8863 4923 7049',
  //},
  //{
  //active: true,
  //label: 'Mar. 14 - Sunday 12:00noon - Saratoga Springs 9th Ward Conference',
  //link: 'https://us02web.zoom.us/j/83961021451?pwd=SVdNL2xpWDc0VXdGaDBRekswb2hlUT09',
  //passcode: '531783',
  //meetingId: '839 6102 1451',
  //},
  //{
  //active: false,
  //label: 'Mar. 14 - Sunday 2:00pm - RS/EQ 2nd Hour with the Stake Presidency',
  //link:
  //'https://us02web.zoom.us/j/81813236396?pwd=a200bVlkQW5ibGFwVWVzWkdDYmtsdz09',
  //passcode: 'SS92NDHOUR',
  //meetingId: '818 1323 6396',
  //},
  //{
  //active: false,
  //label: 'Mar. 14 - Sunday 2:00pm - Youth (YW/YM) 2nd hour with the Stake',
  //link: 'https://us02web.zoom.us/j/85254928322?pwd=ZlZ3MWJNOXNwZmtVQWViLzU3djZHZz09',
  //passcode: '101782',
  //meetingId: '852 5492 8322',
  //},
] as Broadcast[];

DATA.people = {
  memberOfBishopric: { name: 'Member of Bishopric' },
  chorister1: { name: 'Sister Nyoka Mataele' },
  chorister2: { name: 'Sister Kalo Toroca' },
  organist1: { name: "Sister Keili Malohifo'ou" },
  organist2: { name: 'Brother Travis Tuiloma' },
  tbd: { name: 'TBD' },
  pray1: { name: 'Monika Zayden' },
  pray2: { name: 'Chris Faaliliu' },
  hc: { name: '(Stake High Councilman)' },
  bp1stc: { name: 'Brother Salesi Sika' },
  bp2ndc: { name: 'Brother Ricky Tuihalangingie' },
  wc: { name: 'Brother Eathyn Manumaleuna' },
  bp: { name: 'Bishop Wayne Tarawhiti' },
  stkpresc2: { name: 'President Spencer Reid' },
  stkpresc1: { name: 'President Darren Harris' },
  stkpres: { name: 'President Richard Paongo' },
  premusic: { name: 'TBD' },
};

DATA.addresses = {
  chapel: {
    reference: '1178 S Pondside Dr, Saratoga Springs, UT 84045',
    link: 'https://goo.gl/maps/7vHGQT6v1RU4VdgG9',
  },
};

DATA.releases = [
  // {
  //   name: '',
  //   calling: '',
  // },
  {
    name: '',
    calling: '',
  },
  {
    name: '',
    calling: '',
  },
  // {
  //   name: 'Sister Genesis Toki',
  //   calling: 'Primary Worker',
  // },
  // {
  //   name: 'Sister Paige Nagtikaura',
  //   calling: 'Sunday School Teacher',
  // },
  // // {
  //   name: 'Brother Alipate Prescott',
  //   calling: 'Sunday School President',
  // },
  // {
  //   name: 'Brother Stan Raas',
  //   calling: '1st Counselor Sunday School Presidency',
  // },
  // {
  //   name: 'Brother Eric Funn',
  //   calling: '2nd Counselor Sunday School Presidency',
  // },
  // {
  //   name: 'Brother Esafe Mile',
  //   calling: 'Secretary Sunday School Presidency',
  // },
  // {
  //   name: 'Brother Joe Hafoka',
  //   calling: 'Sunday School Teacher',
  // },
  // {
  //   name: 'Sister Shelli Hafoka',
  //   calling: 'Sunday School Teacher',
  // },
];

DATA.sustaining = [
  {
    name: '',
    calling: '',
  },
  // {
  //   name: 'Sister Brooke Molisi',
  //   calling: 'RS Activity Committee',
  // },
  // {
  //   name: 'Sister Jessica Wertner',
  //   calling: 'RS Teacher',
  // },
  // {
  //   name: 'Sister Shelli Hafoka',
  //   calling: 'RS Assistant Secretary',
  // },
  // {
  //   name: 'Brother Auka Fifita',
  //   calling: 'Primary Worker',
  // },
  // {
  //   name: 'Sister Sue Unutoa',
  //   calling: 'Primary Primary Worker',
  // },
  // {
  //   name: 'Sister Candice Kalamafoni',
  //   calling: 'Primary Worker (Music Coordinator)',
  // },
  // {
  //   name: 'Sister Siosiana Kalamafoni',
  //   calling: 'Primary Worker (Music Coordinator)',
  // },
  // {
  //   name: 'Sister Paige Ngatikaura',
  //   calling: 'Primary Worker',
  // },
  // {
  //   name: 'Sister Holly Terrell',
  //   calling: 'Sunday School Teacher',
  // },
  // {
  //   name: '',
  //   calling: '',
  // },
];

DATA.aaronicPriesthoodAdvancements = [
  // { name: 'Brother Zealyn Unutoa', calling: 'Deacon' },
  // { name: 'Brother Lyman (Mani) Rarick', calling: 'Deacon' },
  // { name: 'Brother Kaleo Teriipaia', calling: 'Deacon' },
  // { name: 'Brother Panasai Soakai', calling: 'Deacon' },
  // { name: 'Brother Malakai Kalamafoni', calling: 'Deacon' },
  // { name: 'Brother Finau Lomu', calling: 'Deacon' },
  // { name: 'Brother Simeni Tuivai Jr.', calling: 'Deacon' },
  // { name: 'Brother Jacob Tuʻifua', calling: 'Teacher' },
  // { name: 'Brother Semisi Tonga', calling: 'Teacher' },
  // { name: 'Brother Aiseah Hafoka', calling: 'Teacher' },
  // { name: 'Brother David Toki', calling: 'Teacher' },
  // { name: 'Brother William Harris', calling: 'Priest' },
];
// DATA.aaronicPriesthoodAdvancementsTeacher = [
//   { name: 'Brother Zealyn Unutoa' },

//   ];
// DATA.aaronicPriesthoodAdvancementsPriest = [
//   { name: 'Brother Zealyn Unutoa' },
// ];

DATA.newMembers = [
  { name: 'Kylie Nicole Bloomfield' },
  { name: 'Sionepepe Bloomfield' },
  { name: 'Kalisi Bloomfield' },
  { name: 'Tefua Bloomfield' },
  { name: 'Vunivalu Bloomfield' },
  { name: 'Montella Lisa Naa' },
  { name: 'Siaki Fekitoa Naa' },
  { name: 'Siaki Fekitoa Naa Jr' },
  { name: 'Nancy Sunitasia Fifita' },
  { name: 'Trina Falatea' },
  { name: 'Mikennah Falatea' },
  { name: 'Leatasinarose Falatea' },
  { name: 'Rene Taito' },
  { name: 'Shalia Elizabeth Taito' },
];

DATA.baptisms = [
  // { name: 'Vaoahi Prescott' },
  // { name: 'Elliot Martin' },
  // { name: 'William Christopher Pita' },
  // { name: 'Isileli George Pita' },
];

DATA.babyBlessing = [{ name: '' }];

DATA.missionaries = [
  // {
  //   name: 'Seamus Fitzgerald',
  //   mission: 'Arizona Gilbert',
  //   startDate: new Date(2020, 9, 14),
  //   endDate: new Date(2022, 8, 28),
  //   photoUrl: './assets/people/elder-fitzgerald.png',
  // },
  {
    name: 'Kaleinani Fitzgerald',
    mission: 'Utah St. George',
    startDate: new Date(2022, 1, 22),
    endDate: new Date(2023, 7, 16),
    photoUrl: './assets/people/SisFitzgerald.jpg',
  },
];

//THIS IS THE TEMPLATE OF THE SACRAMENT PROGRAM WHICH ALSO GOES TO THE CHEAT SHEET.
//UPDATE THE FOLLOWING: date, conducting, chorister, organist, invocation & benediction, hymns, speakers/testimonies
//NOTE: If it's a FAST SUNDAY, change the "testimonySunday" section to "true"
//NOTE:
DATA.cheatSheet = {
  zoomSession: true,
  sacrament: SACRAMENT_PROGRAM_DATA,
  stakeVisitors: [] as Person[],
  announcements: DATA.announcements,
  releases: DATA.releases,
  sustaining: DATA.sustaining,
  newMembers: DATA.newMembers,
  babyBlessing: DATA.babyBlessing,
  aaronicPriesthoodAdvancements: DATA.aaronicPriesthoodAdvancements,
  // aaronicPriesthoodAdvancementsTeacher: DATA.aaronicPriesthoodAdvancementsTeacher,
  // aaronicPriesthoodAdvancementsPriest: DATA.aaronicPriesthoodAdvancementsPriest,
  baptisms: DATA.baptisms,
};
